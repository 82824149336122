import React from 'react';
// import {
//   DesktopOutlined,
//   SettingOutlined,
//   CustomerServiceOutlined,
//   FileTextOutlined,
//   FileSyncOutlined,
//   DashboardOutlined,
//   TeamOutlined,
//   UserOutlined,
//   CreditCardOutlined,
//   BankOutlined,
// } from "@ant-design/icons";

// export const IconMenu = ({ name }) => {
//   const components = {
//     DesktopOutlined: DesktopOutlined,
//     SettingOutlined: SettingOutlined,
//     CustomerServiceOutlined: CustomerServiceOutlined,
//     FileTextOutlined: FileTextOutlined,
//     FileSyncOutlined: FileSyncOutlined,
//     DashboardOutlined: DashboardOutlined,
//     TeamOutlined: TeamOutlined,
//     UserOutlined: UserOutlined,
//     CreditCardOutlined: CreditCardOutlined,
//     BankOutlined: BankOutlined,
//     Default: DesktopOutlined,
//   };

//   const IconTag = components[name || "Default"] || SettingOutlined;
//   return <IconTag />;
// };


export const routesConfig = [
  {
    path: "/",
    component: "Dashboard",
  },
  {
    path: "/reservation",
    component: "Reservation",
  },
  {
    path: "/customer",
    component: "Customer",
  },
  {
    path: "/car",
    component: "Car",
  },
  {
    path: "/employee",
    component: "Employees",
  },
  {
    path: "/maintance",
    component: "Maintance",
  },
  {
    path: "/expense_entry",
    component: "Expenses",
  },
  {
    path: "/payment",
    component: "Payment",
  },
  {
    path: "/task_management",
    component: "TaskManagement",
  },
  {
    path: "/account/other",
    component: "OtherAccount",
  },
  {
    path: "/transaction",
    component: "Transaction",
  },
  {
    path: "/settings",
    component: "Settings/Settings",
  },
  {
    path: "/useractivity",
    component: "UserActivity",
  },
  {
    path: "/addonitem",
    component: "AddOnItem",
  },
  {
    path: "/user_permission",
    component: "UserPermission",
  },
  {
    path: "/transfer_approval",
    component: "CashTransfer",
  },
  {
    path: "/car_maintanance",
    component: "CarMaintanance",
  },
  {
    path: "/salik_list",
    component: "Salik",
  },
  {
    path: "/fine_list",
    component: "Fines",
  },
  {
    path: "/availability_calender",
    component: "AvailabilityCalender",
  },
];
